import { Paragraph, Heading, Icon, Logo } from '@mediahuis/chameleon-react';
import { ExternalLink } from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';
import type { ReactElement } from 'react';

import { brandConfig } from '@hubcms/brand';
import { ImageElement } from '@hubcms/ui-image';
import { Labels } from '@hubcms/ui-taxonomy';

import { TeaserLink } from '../TeaserLink';

import styles from './opinion-block-teaser.module.scss';
import type { OpinionBlockTeaserProps } from './types';

const OpinionBlockTeaser = ({ data, imagePriority, className, mobileRow, odd }: OpinionBlockTeaserProps): ReactElement | null => {
  if (!data.url || !data.title) {
    return null;
  }

  const authorImageURL = data?.authors?.[0]?.image?.url;
  const authorImageHeight = data?.authors?.[0]?.image?.height || 400;
  const authorImageWidth = data?.authors?.[0]?.image?.width || 400;
  const authorName = data?.authors?.[0]?.name;

  return (
    <article className={cx(styles.opinionBlockTeaser, { [styles.odd]: odd })}>
      <TeaserLink className={styles.link} isExternal={data.isExternal} url={data.url} trackingData={data.trackingData}>
        <div className={cx(styles.teaser, { [styles.mobileRow]: mobileRow }, className)}>
          <Labels
            className={styles.labels}
            isPremium={data.isPremium}
            sublabel={data.subLabel}
            label={data.label}
            showPremiumIcon={brandConfig.opinionTeaserPremiumIconPosition === 'label'}
          />

          <Heading level={3} data-testid="teaser-heading" className={styles.title}>
            {brandConfig.opinionTeaserPremiumIconPosition === 'title' && data.isPremium && (
              <Logo name="logo-premium-main" className={styles.premiumIcon} />
            )}
            {data.title}
            {data.isExternal && <Icon className={styles.externalLinkIcon} as={ExternalLink} />}
          </Heading>
          {data.introduction && (
            <Paragraph as="blockquote" className={styles.opinionIntro}>
              {data.introduction}
            </Paragraph>
          )}
          {authorImageURL && authorImageHeight && authorImageWidth && (
            <ImageElement
              data-testid="teaser-image"
              className={styles.image}
              src={authorImageURL}
              alt={authorName}
              sizes={{ sm: '240px', md: '506px', lg: '240px' }}
              priority={imagePriority}
              aspectRatio="oneOne"
            />
          )}
        </div>
      </TeaserLink>
    </article>
  );
};

export default OpinionBlockTeaser;
